import { EPaymentState, ICustomer, IOrder } from "@middleware/types";
import { Content } from "@prismicio/client";
import { useEffect, useState } from "react";
import { getIsIssuerOrder, getIssueMessage } from "@middleware/helpers";
import { RichTextField } from "@prismicio/types";
import { createClient } from "../../../prismicio";
import { useOrders } from "./useOrders";
import { useStagingMode } from "./useStagingMode";

export const useUnpaidOrder = (customer: ICustomer | undefined) => {
  const [issueOrder, setIssueOrder] = useState<IOrder>();
  const [reguleOrder, setReguleOrder] = useState<IOrder>();
  const [message, setMessage] = useState<RichTextField>();
  const [closedSubscriptionNoPaid, setClosedSubscriptionNoPaid] =
    useState<boolean>();
  const [banner, setBanner] = useState<
    | Content.PaymentReminderBannerDocument
    | Content.PaymentReminderBannerStagingDocument
  >();
  const { orders, loading } = useOrders();
  const { isStagingMode } = useStagingMode();

  useEffect(() => {
    const getReguleAndIssueOrder = () => {
      const getOrders = () => {
        if (customer === undefined || !orders) return;

        const unpaidOrder = orders.find((order) => getIsIssuerOrder(order));
        const regule = orders.find((order) => order.regularisation === true);

        setReguleOrder(regule);
        setIssueOrder(unpaidOrder);
      };
      void getOrders();
    };

    void getReguleAndIssueOrder();
  }, [customer, orders]);

  useEffect(() => {
    const getMessages = async () => {
      const client = createClient();
      const bannerData = isStagingMode
        ? await client.getSingle("paymentReminderBanner_staging")
        : await client.getSingle("paymentReminderBanner");
      setBanner(bannerData);
    };
    void getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const customerPaymentState = customer?.currentSubscription?.paymentState;
    const isClosedSubscriptionNoPaid =
      customerPaymentState === EPaymentState.CLOSED_AUTOMATICALLY ||
      customerPaymentState === EPaymentState.CLOSED_MANUALLY;
    const messageState = isClosedSubscriptionNoPaid
      ? customerPaymentState
      : issueOrder
      ? issueOrder.paymentState
      : undefined;

    const newMessage =
      banner !== undefined && messageState !== undefined
        ? getIssueMessage(banner.data, messageState)
        : undefined;

    setMessage(newMessage);
    setClosedSubscriptionNoPaid(isClosedSubscriptionNoPaid);
  }, [banner, issueOrder, customer]);

  return {
    issueOrder,
    setIssueOrder,
    reguleOrder,
    setReguleOrder,
    message,
    setMessage,
    loadingIssueOrder: loading,
    closedSubscriptionNoPaid,
  };
};
