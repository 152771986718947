import WrapApi from "@middleware/helpers/api/wrapApi.class";
import { AxiosError, AxiosResponse } from "axios";
import {
  IEvaluation,
  IFacebookLogin,
  IGoogleLogin,
  ILoginIn,
  IResponseToken,
  UserType,
} from "@middleware/types";

export default class Authentication extends WrapApi {
  recovery = async (
    emailRecovery: string
  ): Promise<AxiosResponse | AxiosResponse<AxiosError>> => {
    return await this.postResource("shop/reset-password-requests", {
      email: emailRecovery,
    });
  };

  reset = async (
    token: string,
    password: string
  ): Promise<AxiosResponse<IResponseToken> | AxiosResponse<AxiosError>> => {
    return await this.patchResource<IResponseToken>(
      `shop/reset-password-requests/${token}`,
      { newPassword: password, confirmNewPassword: password }
    );
  };

  login = async (
    userData: ILoginIn
  ): Promise<AxiosResponse<IResponseToken> | AxiosResponse<AxiosError>> => {
    return await this.postResource<IResponseToken>(
      `shop/authentication-token`,
      userData
    );
  };

  googleLogin = async (
    userData: IGoogleLogin
  ): Promise<AxiosResponse<IResponseToken> | AxiosResponse<AxiosError>> => {
    return await this.postResource<IResponseToken>(
      `shop/google-authentication-token`,
      userData
    );
  };

  facebookLogin = async (
    userData: IFacebookLogin
  ): Promise<AxiosResponse<IResponseToken> | AxiosResponse<AxiosError>> => {
    return await this.postResource<IResponseToken>(
      `shop/facebook-authentication-token`,
      userData
    );
  };

  register = async (
    userData: UserType,
    utmParams?: { [key: string]: string | unknown | null }
  ): Promise<AxiosResponse<UserType> | AxiosResponse<AxiosError>> => {
    return await this.postResource<UserType>(
      "shop/customers",
      userData,
      null,
      utmParams
    );
  };

  updatePassword = async (
    customerId: number,
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ): Promise<AxiosResponse<IResponseToken> | AxiosResponse<AxiosError>> => {
    return await this.putResource<IResponseToken>(
      `shop/customers/{id}/password`,
      { currentPassword, newPassword, confirmNewPassword },
      {
        id: customerId,
      }
    );
  };

  registerByEvaluation = async (
    userData: IEvaluation,
    utmParams?: { [key: string]: string | unknown | null }
  ): Promise<AxiosResponse | AxiosResponse<AxiosError>> => {
    return await this.postResource(
      "shop/customers/evaluation",
      userData,
      null,
      utmParams
    );
  };

  refreshToken = async (
    refreshToken: string
  ): Promise<AxiosResponse<IResponseToken> | AxiosResponse<AxiosError>> => {
    return await this.postResource<IResponseToken>(
      "shop/refresh-authentication-token",
      {
        refreshToken,
      }
    );
  };
}
