import { MouseEvent, SyntheticEvent } from "react";
import { Menu, NavBar } from "@components/commun";
import { Container } from "@components/ui";
import { EDevice, EFrontType } from "@middleware/types";
import { useSteps } from "@middleware/hooks/useSteps";
import { useClearAutentification, useUI } from "@middleware/hooks";
import { StepsProgrammes } from "@components/catalog/steps/StepsProgrammes";
import { getFromStorage } from "@middleware/helpers/global/sessions";
import { FRONT_SESSION_CODE, URL_HOME } from "@middleware/constants";
import style from "./Header.module.scss";

export const Header = () => {
  const { resetAutentification } = useClearAutentification();
  const {
    openModal,
    hideLoader,
    setModalView,
    displayDropdown,
    openDropdown,
    closeDropdown,
  } = useUI();
  const { currentStep } = useSteps();
  const forcingFront = getFromStorage(FRONT_SESSION_CODE) as EFrontType | null;
  const isUserInProgramFunnel =
    currentStep !== null && forcingFront !== EFrontType.STYLE2;

  const showAuthentication = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    openModal();
    setModalView("SIGNUP_VIEW");
    closeDropdown();
  };

  const clearAutentification = async (event: SyntheticEvent) => {
    event.preventDefault();
    await resetAutentification();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(async () => {
      await resetAutentification();
      window.location.href = URL_HOME;
      hideLoader();
    }, 4000);
  };

  return (
    <>
      <header id="lk-header" className={style.header} role="columnheader">
        <Container>
          <div className={`lk-wrap ${style.wrap}`}>
            <div className="lk-flex flex-wrap space-between">
              <div className={`show-tab-mobile ${style.hamburger}`}>
                {!displayDropdown && (
                  <a
                    href="!#"
                    onClick={(e) => {
                      e.preventDefault();
                      openDropdown();
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </a>
                )}
                {displayDropdown && (
                  <a
                    href="!#"
                    onClick={(e) => {
                      e.preventDefault();
                      closeDropdown();
                    }}
                    className={style.close}
                  >
                    Close
                  </a>
                )}
              </div>
              <NavBar
                showModal={showAuthentication}
                logout={clearAutentification}
              />
            </div>
          </div>
          {displayDropdown && (
            <div className={style.mobileMenu}>
              <Menu
                device={EDevice.MOBILE}
                showModal={showAuthentication}
                logout={clearAutentification}
              />
            </div>
          )}
        </Container>
      </header>
      {isUserInProgramFunnel && <StepsProgrammes />}
    </>
  );
};
