import { MEALS_TAG } from "@middleware/constants";
import { useLocalStorage } from "react-use";

export const useTagsPreferences = (): [
  tag: string | undefined,
  toggleTag: (code?: string) => void
] => {
  const [tag, setTag, removeTag] = useLocalStorage<string>(MEALS_TAG);

  const toggleTag = (newTag?: string) => {
    if (newTag === undefined) {
      removeTag();
    } else {
      setTag(newTag);
    }
  };

  return [tag, toggleTag];
};
