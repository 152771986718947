import { Eenvironment } from "@middleware/types";
import * as yup from "yup";

export const serverSchema = yup.object().shape({
  TUNNEL_CONTENT_CACHE: yup.string().required(),
  CMS_CONTENT_CACHE: yup.string().required(),
  EDGE_CONFIG: yup.string().required(),
  SAFE_REVALIDATE_TOKEN: yup.string().required(),
  BASIC_AUTH_ENABLED: yup.string().required(),
  BASIC_AUTH_USER: yup.string().required(),
  BASIC_AUTH_PASSWORD: yup.string().required(),
});
export const serverEnv = {
  TUNNEL_CONTENT_CACHE: process.env.TUNNEL_CONTENT_CACHE as string,
  CMS_CONTENT_CACHE: process.env.CMS_CONTENT_CACHE as string,
  EDGE_CONFIG: process.env.EDGE_CONFIG as string,
  SAFE_REVALIDATE_TOKEN: process.env.SAFE_REVALIDATE_TOKEN as string,
  BASIC_AUTH_ENABLED: process.env.BASIC_AUTH_ENABLED as string,
  BASIC_AUTH_USER: process.env.BASIC_AUTH_USER as string,
  BASIC_AUTH_PASSWORD: process.env.BASIC_AUTH_PASSWORD as string,
};

export const clientSchema = yup.object().shape({
  NEXT_PUBLIC_ENV: yup.string().required(),
  NEXT_PUBLIC_CHANNEL_CODE: yup.string().required(),
  NEXT_PUBLIC_API_URL: yup.string().url().required(),
  NEXT_PUBLIC_STORE_URL: yup.string().url().required(),
  NEXT_PUBLIC_FACEBOOK_APP_ID: yup.string().required(),
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: yup.string().required(),
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: yup.string().required(),
  NEXT_PUBLIC_SENTRY_DSN: yup.string(),
  NEXT_PUBLIC_CONTENT_SECURITY_POLICY: yup.string().required(),
  NEXT_PUBLIC_PAYZEN_PUBLIC_KEY: yup.string().required(),
  NEXT_PUBLIC_GTM_URL: yup.string().required(),
  NEXT_PUBLIC_GTM_ID: yup.string().required(),
  NEXT_PUBLIC_CHRONOPOST_TRACKING: yup.string().required(),
  NEXT_PUBLIC_CONTACT_NUMBER: yup.string().required(),
  NEXT_PUBLIC_CONTACT_EMAIL: yup.string().required(),
  NEXT_PUBLIC_AVAILABLE_COUNTRIES: yup.string().required(),
  NEXT_PUBLIC_SECURE_PAYZEN: yup.string().required(),
  NEXT_PUBLIC_STATIC_PAYZEN: yup.string().required(),
  NEXT_PUBLIC_INSTAGRAM_URL: yup.string().required(),
  NEXT_PUBLIC_YOUTUBE_URL: yup.string().required(),
  NEXT_PUBLIC_FACEBOOK_URL: yup.string().required(),
  NEXT_PUBLIC_FACEBOOK_GROUP_URL: yup.string().required(),
});
export const clientEnv = {
  NEXT_PUBLIC_CHANNEL_CODE: process.env.NEXT_PUBLIC_CHANNEL_CODE as string,
  NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL as string,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV as Eenvironment,
  NEXT_PUBLIC_STORE_URL: process.env.NEXT_PUBLIC_STORE_URL as string,
  NEXT_PUBLIC_FACEBOOK_APP_ID: process.env
    .NEXT_PUBLIC_FACEBOOK_APP_ID as string,
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: process.env
    .NEXT_PUBLIC_GOOGLE_CLIENT_ID as string,
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env
    .NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN as string,
  NEXT_PUBLIC_CONTENT_SECURITY_POLICY: process.env
    .NEXT_PUBLIC_CONTENT_SECURITY_POLICY as string,
  NEXT_PUBLIC_PAYZEN_PUBLIC_KEY: process.env
    .NEXT_PUBLIC_PAYZEN_PUBLIC_KEY as string,
  NEXT_PUBLIC_GTM_URL: process.env.NEXT_PUBLIC_GTM_URL as string,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID as string,
  NEXT_PUBLIC_CHRONOPOST_TRACKING: process.env
    .NEXT_PUBLIC_CHRONOPOST_TRACKING as string,
  NEXT_PUBLIC_CONTACT_NUMBER: process.env.NEXT_PUBLIC_CONTACT_NUMBER as string,
  NEXT_PUBLIC_CONTACT_EMAIL: process.env.NEXT_PUBLIC_CONTACT_EMAIL as string,
  NEXT_PUBLIC_AVAILABLE_COUNTRIES: process.env
    .NEXT_PUBLIC_AVAILABLE_COUNTRIES as string,
  NEXT_PUBLIC_SECURE_PAYZEN: process.env.NEXT_PUBLIC_SECURE_PAYZEN as string,
  NEXT_PUBLIC_STATIC_PAYZEN: process.env.NEXT_PUBLIC_STATIC_PAYZEN as string,
  NEXT_PUBLIC_INSTAGRAM_URL: process.env.NEXT_PUBLIC_INSTAGRAM_URL as string,
  NEXT_PUBLIC_YOUTUBE_URL: process.env.NEXT_PUBLIC_YOUTUBE_URL as string,
  NEXT_PUBLIC_FACEBOOK_URL: process.env.NEXT_PUBLIC_FACEBOOK_URL as string,
  NEXT_PUBLIC_FACEBOOK_GROUP_URL: process.env
    .NEXT_PUBLIC_FACEBOOK_GROUP_URL as string,
};
