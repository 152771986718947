import { ESegmentCustomer } from "../global/communsType";

export interface IResourceImage {
  id: number;
  path: string;
  altText?: string;
  width?: number;
  height?: number;
}
export interface ITaxonSlugTranslation {
  translations?: {
    [key: string]: ITaxonTranslation;
  };
}
export interface ITaxonTranslation {
  id: number;
  name: string;
  locale: string;
  slug?: string;
  description: string;
}
export interface ITaxon extends ITaxonSlugTranslation {
  parent?: ITaxon | null;
  name?: string;
  id: number;
  code?: string;
  position?: number;
}
export interface IProductTaxon {
  id: number;
  name?: string;
  taxon: ITaxon;
}
export interface IVariant {
  "@id": string;
  id: number;
  code: string;
  inStock: boolean;
  price: number;
  onHand: number;
  volume: number;
  weight: number;
  channelPricings: {
    [key: string]: {
      channelCode: string;
      price: number;
    };
  };
}

export interface IObjectif {
  id: number;
  label: string;
  more?: boolean;
}

export type IProfilPromo = 1 | 2 | 3 | 4 | 7;
export type ITypePromo = "promo1" | "promo2" | "promo3";
export interface IControlProducts {
  id: number;
  code: string;
  quantity: number;
  price: number;
  packaging: number;
}
export interface IControl {
  products: IControlProducts[];
}
export interface IFilter {
  code: string;
  name: string;
  image: string;
}
export type IChannel = {
  id: number;
  code: string;
  date?: Date;
};
export interface IObject {
  [key: string]: string;
}

export interface IBundleAttribute {
  localeCode: string | null;
  attributeName: string;
  attributeCode: string;
  value: number | string | string[] | boolean | IObject | IObject[];
}
export interface IProductResponse {
  id: number;
  code: string;
  name: string;
  shortDescription: string;
  description: string;
  conditioning: string;
  usageTips: string;
  ingredients: string;
  nutritionalData: string;
  images: IResourceImage[];
  attributes?: IBundleAttribute[];
  variants: IVariant[];
  productTaxons: IProductTaxon[];
  packaging: number | undefined;
  isNew?: boolean;
  isBest?: boolean;
  priority?: number;
  desktopPosition?: number;
  mobilePosition?: number;
  bundles?: string[];
}
export interface IMenuResponse {
  id: number;
  code: string;
  slot: string;
  weeklyMenuCode: string;
  items: IMenuItemResponse[];
}
export interface IMenuItemResponse {
  id: number;
  new: boolean;
  priority: number;
  product: IProductResponse;
}
export interface IMenuItem {
  meals: IProduct[];
  weeklyMenuCode: string;
}
export interface IProduct {
  id: number;
  code: string;
  codeVariant: string;
  price: number;
  mealsNumber: number;
  maxMealsNumber: number;
  isDefault: boolean;
  checkoutEnabled: boolean;
  name: string;
  shortDescription: string;
  description: string;
  image: string;
  isNew: boolean;
  isBest: boolean;
  priority: number;
  foodPreference: string[];
  nutriscore: string[];
  ingredients: string;
  nutritional: string;
  defaultCouponCode: string | null;
  preparation: string;
  packaging: number;
  volume: number | null;
  weight: number | null;
  storageInstructions: string[];
  isCustomBundle: boolean;
  productTaxons?: IProductTaxon[];
  desktopPosition: number;
  mobilePosition: number;
  tags?: TTags[];
  showInBundles: string[];
}
export enum ERuleTypeValue {
  NTH = "nth",
  SEGMENT = "segments",
}
export enum ERuleType {
  RULE_FIRST_ORDER = "nth_customer_order",
  RULE_SEGMENT = "segment",
}
export type IPromotionRuleConfigurationValue = number | ISegmentConfiguration;
export type IPromotionRuleConfiguration = Record<
  ERuleTypeValue,
  IPromotionRuleConfigurationValue
>;
export interface IPromotionCouponRuleResponse {
  id: number;
  type: ERuleType;
  configuration: IPromotionRuleConfiguration;
  promotion: string;
}

export enum DiscountTypeValue {
  AMOUNT = "amount",
  PERCENTAGE = "percentage",
}
export type IPromotionActionConfiguration = Record<DiscountTypeValue, number>;

export type IPromotionCouponActionResponse =
  | IPromotionCouponDiscountActionResponse
  | IPromotionCouponAssigningActionResponse;

export enum CHANNEL_CODES {
  KITCHEN_DAILY = "KITCHEN_DAILY",
}
export interface TPromotionActionConfiguration {
  amount: number;
  percentage: number;
  variants_filter?: {
    variants: string[];
  };
}
export interface IPromotionCouponDiscountActionResponse {
  actionType: "discount";
  id: number;
  type: DiscountType;
  configuration:
    | Record<CHANNEL_CODES, TPromotionActionConfiguration>
    | TPromotionActionConfiguration;
  promotion: string;
}

export interface IPromotionCouponAssigningActionResponse {
  actionType: "assigner";
  id: number;
  type: AssigningType;
  configuration: Record<string, string>;
  promotion: string;
}

export interface IPromotionCouponsResponse {
  channels: string[];
  id: number;
  code: string;
  name: string;
  description: string;
  priority: number;
  exclusive: true;
  usageLimit: number;
  used: number;
  startsAt: string;
  endsAt: string;
  couponBased: true;
  coupons: string[];
  rules: IPromotionCouponRuleResponse[];
  actions: IPromotionCouponActionResponse[];
  appliesToDiscounted: true;
  createdAt: string;
  updatedAt: string;
}
export type ISegmentConfiguration = Record<number, ESegmentCustomer>;
export interface BundlePromotion {
  code: string;
  bundleCode: string;
  promotionCoupon: string;
  discountType: DiscountType;
  discountAmount: number;
  firstOrder: boolean;
  groupCode: string | null;
  subscriptionTypeCode: string | null;
  variants: string[];
  segments?: ISegmentConfiguration;
}

export enum DiscountType {
  UNIT_PERCENTAGE = "unit_percentage_discount",
  UNIT_FIXED = "unit_fixed_discount",
  ORDER_PERCENTAGE = "order_percentage_discount",
  ORDER_FIXED = "order_fixed_discount",
  VARIANT_FIXED = "variant_unit_fixed_discount",
}

export enum AssigningType {
  GROUP_ASSIGNER = "assign_customer_to_group",
  SUBSCRIPTION_ASSIGNER = "subscription",
}

export type BundlePromotionDetails = Omit<BundlePromotion, "bundleCode">;

export type BundleCodePromotionMap = Record<string, BundlePromotionDetails>;

export enum EProductType {
  PRODUCT_MEALS = "repas",
  PRODUCT_EXTRAS = "extras",
  PRODUCT_OFFERED = "offered",
}

export enum EBundlesType {
  CLASSIC = "classic",
  CUSTOM = "custom",
}
export interface TNoveltyOption {
  values: { new: boolean; channel: string }[];
}

export interface TTags {
  code: string;
  icon?: string;
  name?: string;
  caption?: string;
  image?: string;
}

export enum EProgramType {
  TRAITEUR = "traiteur",
  ECO = "eco",
  NONE = "none",
}

export interface IProgram {
  code: string;
  codeVariant: string;
  productTaxon: EProgramType;
  price: number;
  mealsNumber: number;
  defaultCouponCode: string | null;
}
