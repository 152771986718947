import {
  EPaymentMethods,
  ILocalStorageCart,
  IOrder,
  IOrderItem,
  IProduct,
  ISchedule,
} from "@middleware/types";
import {
  ADMINISTRATION_FEES,
  BUNDLE_DAILY_ECO_TAXON_CODE,
  CART_TOKEN_STORAGE,
  CODE_BUNDLE_MAIN_TAXON,
  CODE_LOYALTY,
  PREFEX_CUSTOM_BUNDLE,
} from "@middleware/constants";
import Api from "@middleware/api";
import { client as env } from "@config/env/client";
import {
  generateDate,
  getNumberFromString,
  getOrderExtras,
  getOrderMeals,
  getPreciseSlot,
} from "./utils";
import { getFromStorage } from "./sessions";
import { getOrderFailedPaymentLevel } from "./catalog";

export const getShippingMethodValues = (
  shippingMethods: ISchedule,
  currentDay: string
) => {
  if (!(currentDay in shippingMethods)) return [];

  return shippingMethods[currentDay].shipping_methods
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((method) => ({
      label: method.description,
      value: method.code,
      type: method.type,
    }));
};

export const getNextOrderToken = (currentOrder: IOrder, orders?: IOrder[]) => {
  if (!orders) return null;

  const currentDate = generateDate(currentOrder.chosenDeliveryDate);

  const nextOrder = orders.find(
    (order) =>
      generateDate(order.chosenDeliveryDate) > currentDate &&
      getOrderFailedPaymentLevel(order) === null
  );

  return nextOrder ? nextOrder.tokenValue : null;
};

export const getFormattedAddress = (order: IOrder) => {
  const shippingAddress = order.shippingAddress;
  const shipmentAddressLine1 = shippingAddress
    ? shippingAddress.street +
      (shippingAddress.complement !== undefined
        ? ", " + shippingAddress.complement
        : "")
    : "";

  const shipmentAddressLine2 = shippingAddress
    ? shippingAddress.postcode + ", " + shippingAddress.city
    : "";

  return { shipmentAddressLine1, shipmentAddressLine2 };
};

export const getShippingSlotsValues = (
  shippingMethods: ISchedule,
  currentDay: string
) => {
  if (!(currentDay in shippingMethods)) return [];

  return shippingMethods[currentDay].delivery_slots?.map((slot) => ({
    label: getPreciseSlot(slot.date),
    value: slot,
  }));
};

export const getShipmentTrackingUrl = (shipmentTracking: string) => {
  return (
    env.NEXT_PUBLIC_CHRONOPOST_TRACKING + "?listeNumerosLT=" + shipmentTracking
  );
};

export const getOrderLoyalty = (order: IOrder) => {
  return (
    order.adjustments.find(
      (adjustment) => adjustment.originCode === CODE_LOYALTY
    ) ?? null
  );
};

export const getTotalWithoutDiscount = (order: IOrder) => {
  const discount = order.orderPromotionTotal;

  return order.total - discount;
};

export const getCodeBundleOfOrder = (order: IOrder) => {
  return order.items.find(
    (item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON
  )?.productCode as string;
};

export const getVariantCodeBundleOfOrder = (order: IOrder) => {
  return order.items.find(
    (item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON
  )?.variantCode as string;
};

export const getPriceBundleOfOrder = (order: IOrder) => {
  return (
    order.items.find((item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON)
      ?.unitPrice ?? 0
  );
};

export const getFormulasMealsNumber = (order: IOrder) => {
  const codeBundle = getCodeBundleOfOrder(order);

  return getNumberFromString(codeBundle);
};

export const updateCartFromStorage = async (
  cart: IOrder | undefined,
  setCart: (order: IOrder) => void
) => {
  const cartToken = getFromStorage(CART_TOKEN_STORAGE) as
    | ILocalStorageCart
    | undefined;
  if (cart && cart.tokenValue !== cartToken?.token) {
    const currentOrder = await Api.cart.getCartByToken(
      cartToken?.token as string
    );
    setCart(currentOrder);
  }
};
export const getOrderBundleItem = (order: IOrder) => {
  return order.items.find(
    (item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON
  );
};
export const hasOrderItemTaxon = (
  item: IOrderItem | undefined,
  codeTaxon: string
) => {
  if (!item) return false;

  return (
    item.product.productTaxons.find(
      (taxon) => taxon.taxon.code === codeTaxon
    ) !== undefined
  );
};

export const isEcoOrder = (order: IOrder) => {
  const orderBundle = getOrderBundleItem(order);
  if (orderBundle) {
    return hasOrderItemTaxon(orderBundle, BUNDLE_DAILY_ECO_TAXON_CODE);
  }

  return false;
};
export const isMegratedPaybox = (order: IOrder) => {
  if (order.subscription) return order.subscription.isMigratedPayboxData;

  return false;
};
export const getRemainingDeferral = (order: IOrder) => {
  if (order.subscription) return order.subscription.remainingDeferral;

  return 0;
};
export const getEnabledPaymentMethods = () => {
  return [
    EPaymentMethods.CREDIT_CARD,
    EPaymentMethods.SEPA,
    EPaymentMethods.PAYBOX,
  ];
};
export const hasCustomBundle = (order: IOrder) => {
  const customBundle = order.items.find((item) =>
    item.productCode.includes(PREFEX_CUSTOM_BUNDLE)
  );

  return customBundle !== undefined;
};

export const hasMissedMeals = (
  order: IOrder | undefined,
  currentMeals?: IProduct[]
) => {
  if (order === undefined) return false;
  if (currentMeals === undefined) return false;

  const { mealsItems } = getOrderMeals(order);

  const orderMeals = mealsItems.filter((item) =>
    currentMeals.some((meal) => meal.code === item.productCode)
  );

  return orderMeals.length !== mealsItems.length;
};

export const hasMissedExtras = (
  order: IOrder | undefined,
  currentextras?: IProduct[]
) => {
  if (order === undefined) return false;
  if (currentextras === undefined) return false;

  const { extrasItems } = getOrderExtras(order);

  const orderExtras = extrasItems.filter((item) =>
    currentextras.some((meal) => meal.code === item.productCode)
  );

  return orderExtras.length !== extrasItems.length;
};

export const getShipmentNumber = (order: IOrder | undefined) => {
  if (order === undefined) return "";
  if (order.shipments.length === 0) return "";

  return order.shipments[0].number ?? "";
};

export const getReguleApplicationFees = (order: IOrder | undefined) => {
  if (order === undefined) return 0;

  const feesItem = order.items.find(
    (item) => item.productCode === ADMINISTRATION_FEES
  );

  if (feesItem) {
    return feesItem.total;
  }

  return 0;
};
