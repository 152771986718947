import WrapApi from "@middleware/helpers/api/wrapApi.class";
import {
  BundlePromotion,
  BundlePromotionDetails,
  IMenuItem,
  IProduct,
  IPromotionCouponsResponse,
} from "@middleware/types";
import {
  getProductsFromResponse,
  getProductsOfMenu,
  getPromotionCouponFromResponse,
} from "@middleware/helpers";
import { HttpStatusCode } from "axios";
import { DEFAULT_FORMULE_CODE } from "@middleware/constants";

export default class Catalog extends WrapApi {
  getBundles = async (
    order = "ASC",
    ecoProgram = false
  ): Promise<IProduct[]> => {
    const bundlesResponses = await this.getResource(
      "shop/products/type/{type}",
      { type: "bundle" }
    );

    return getProductsFromResponse(bundlesResponses, order, ecoProgram);
  };

  getMeals = async (order = "ASC"): Promise<IProduct[]> => {
    const mealsResponses = await this.getResource("shop/products", null, {
      "productTaxons.taxon.code": "MEAL",
    });

    return getProductsFromResponse(mealsResponses, order);
  };

  getWeeklyMealsByBundle = async (
    param = "current",
    bundleCode = DEFAULT_FORMULE_CODE
  ): Promise<IMenuItem | null> => {
    const mealsResponses = await this.getResource(
      "shop/menus/{param}/bundles/{bundleCode}",
      {
        param,
        bundleCode,
      }
    );

    if (mealsResponses.status !== 200) return null;

    return getProductsOfMenu(mealsResponses);
  };

  getWeeklyMeals = async (param = "current"): Promise<IMenuItem> => {
    const mealsResponses = await this.getResource("shop/menus/{param}", {
      param,
    });

    return getProductsOfMenu(mealsResponses);
  };

  getExtras = async (order = "ASC"): Promise<IProduct[]> => {
    const extrasResponses = await this.getResource(
      "shop/products/type/{type}",
      { type: "EXTRA" }
    );

    return getProductsFromResponse(extrasResponses, order);
  };

  getBundlePromotions = async (): Promise<BundlePromotion[]> => {
    const bundlePromotionsResponses = await this.getResource(
      "shop/promotions/bundles"
    );

    return bundlePromotionsResponses.data as BundlePromotion[];
  };

  getCouponPromotion = async (
    code: string
  ): Promise<BundlePromotionDetails[] | null> => {
    const promotionResponse = await this.getResource(
      `shop/promotion-coupons/${code}/promotion`
    );

    if (promotionResponse.status === HttpStatusCode.Ok)
      return getPromotionCouponFromResponse(
        promotionResponse.data as IPromotionCouponsResponse,
        code
      );

    return null;
  };
}
