import jwt_decode from "jwt-decode";
import { FC, useCallback, useEffect, useMemo, useReducer } from "react";
import { useLocalStorage } from "react-use";
import Api from "@middleware/api";
import {
  CART_TOKEN_STORAGE,
  FOOD_PREFERENCE_FILTERS,
  REMOVE_AUTH_DETAILS,
  SET_AUTH_DETAILS,
} from "@middleware/constants";
import { AuthStateContext } from "@middleware/contexts";
import {
  clearCartTokenFromStorage,
  getFromStorage,
  setToStorage,
} from "@middleware/helpers/global/sessions";
import { authInitialState } from "@middleware/init";
import { authReducer } from "@middleware/reducers";
import {
  generateDate,
  isEmpty,
  updateCustomerFoodPreferences,
} from "@middleware/helpers";
import { AxiosResponse, HttpStatusCode } from "axios";
import {
  AuthInfo,
  IFacebookLogin,
  IGoogleLogin,
  ILoginIn,
  IResponseToken,
  IUser,
  Props,
} from "@middleware/types";
import { useCart, useCustomer } from "@middleware/hooks";

export const AuthProvider: FC<Props> = ({ children }) => {
  const [value, setValue] = useLocalStorage("userInfo", authInitialState);
  const [state, dispatch] = useReducer(authReducer, value ?? authInitialState);
  const { setCustomer } = useCustomer();
  const { loadCart } = useCart();

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      const newState = value ?? authInitialState;
      setValue({
        ...newState,
        ...state,
        isAuthenticated:
          state.isAuthenticated === undefined ? false : state.isAuthenticated,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const getCustomer = async () => {
      if (value?.isAuthenticated === true && value.user && !value.customer) {
        await setConnectedCustomer(state.user);
        await loadCart();
      }
    };

    void getCustomer();
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const setAuthentication = useCallback(
    async (data: AuthInfo) => {
      return new Promise((resolve) => {
        dispatch({ type: SET_AUTH_DETAILS, data });
        resolve("resolved");
      });
    },
    [dispatch]
  );

  const clearAuthentication = useCallback(() => {
    setValue(authInitialState);
    dispatch({ type: REMOVE_AUTH_DETAILS });
    setCustomer(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const login = useCallback(
    async (res: AxiosResponse<IResponseToken>) => {
      const tokenCustomer = res.data.token;
      const refreshToken = res.data.refreshToken;
      const cartTokenValue = res.data.tokenValue;
      const results = jwt_decode<IUser>(tokenCustomer);

      if (tokenCustomer !== "") {
        const userSession = {
          isAuthenticated: !isEmpty(results),
          user: {
            ...results,
            last_login: generateDate().getTime(),
            token: tokenCustomer,
            refreshToken: refreshToken,
          },
          customer: null,
        };
        // connect customer
        await setAuthentication(userSession);
      }
      if (null !== cartTokenValue && "" !== cartTokenValue) {
        setToStorage(CART_TOKEN_STORAGE, { token: cartTokenValue });
      }
    },
    [setAuthentication]
  );

  const loginByEmail = useCallback(
    async (user: ILoginIn) => {
      const res = await Api.authentication.login(user);

      if (res.status !== 200) {
        return false;
      } else {
        await login(res as AxiosResponse<IResponseToken>);

        return true;
      }
    },
    [login]
  );

  const loginByGoogle = useCallback(
    async (user: IGoogleLogin) => {
      const res = await Api.authentication.googleLogin(user);

      if (res.status !== 200) {
        return false;
      } else {
        await login(res as AxiosResponse<IResponseToken>);

        return true;
      }
    },
    [login]
  );

  const loginByFacebook = useCallback(
    async (user: IFacebookLogin) => {
      const res = await Api.authentication.facebookLogin(user);

      if (res.status !== 200) {
        return false;
      } else {
        await login(res as AxiosResponse<IResponseToken>);

        return true;
      }
    },
    [login]
  );

  const impersonateLogin = useCallback(
    async (token: string) => {
      if (token !== "") {
        const results = jwt_decode<IUser>(token);
        const userSession = {
          isAuthenticated: !isEmpty(results),
          user: {
            ...results,
            last_login: generateDate().getTime(),
            token: token,
          },
          customer: null,
        };
        // connect customer
        await setAuthentication(userSession);

        return userSession;
      }

      return { isAuthenticated: false, user: null, customer: null };
    },
    [setAuthentication]
  );

  const logoutUser = useCallback(() => {
    clearAuthentication();
    clearCartTokenFromStorage();
  }, [clearAuthentication]);

  const setConnectedCustomer = useCallback(
    async (user: IUser | null) => {
      if (user) {
        const currentCustomer = await Api.customer.getCustomer(user.id);
        if (currentCustomer) {
          await setAuthentication({
            ...state,
            user,
            customer: currentCustomer,
          });
          const selectedFilters = (getFromStorage(FOOD_PREFERENCE_FILTERS) ??
            []) as string[];
          if (
            selectedFilters.length > 0 &&
            JSON.stringify(selectedFilters) !==
              JSON.stringify(currentCustomer.excludedFoodPreferences)
          ) {
            void updateCustomerFoodPreferences(
              currentCustomer,
              new Set(selectedFilters)
            );
          }
        } else {
          const res = await Api.authentication.refreshToken(
            value?.user?.refreshToken ?? ""
          );
          if (res.status === HttpStatusCode.Ok) {
            await login(res as AxiosResponse<IResponseToken>);
            window.location.reload();
          } else {
            logoutUser();
          }
        }
      }
    },
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const values = useMemo(
    () => ({
      ...state,
      loginByEmail,
      loginByGoogle,
      loginByFacebook,
      impersonateLogin,
      logoutUser,
      setConnectedCustomer,
    }),
    [state] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <AuthStateContext.Provider value={values}>
      {children}
    </AuthStateContext.Provider>
  );
};
