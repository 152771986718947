import {
  BUNDLE_ATTRIBUTE_TYPE,
  BUNDLE_CHECKOUT_ENABLED,
  BUNDLE_CODE_CUSTOM,
  BUNDLE_CODE_DEFAULT,
  BUNDLE_CODE_DEFAULT_COUPON_CODE,
  BUNDLE_CODE_MEALS,
  BUNDLE_CODE_MEALS_MAX,
  BUNDLE_DAILY_ECO_TAXON_CODE,
  BUNDLE_DAILY_TAXON_CODE,
  CODE_EXTRA_MAIN_TAXON,
  CUSTOM_BUNDLE_VALUE,
  FAILED_PAYMENT_STATES,
  FORMULES_TAG_CONTROL_CODE,
  FORMULES_TAG_GENEREUX_CODE,
  FORMULES_TAG_PROTEINE_CODE,
  FORMULES_TAG_VEGE_CODE,
  MEAL_CODE_FOOD_PREFERENCE,
  MEAL_CODE_INGREDIENT_DESCRIPTION,
  MEAL_CODE_NEW,
  MEAL_CODE_NUTRISCORE,
  MEAL_CODE_NUTRITIONAL_VALUE,
  MEAL_CODE_PREPARATION_ADVICE,
  MEAL_CODE_STORAGE_INSTRUCTIONS,
  TAXON_TAGS_CODE,
} from "@middleware/constants";
import { getLocale } from "@config/locales";
import {
  AssigningType,
  BundlePromotionDetails,
  CHANNEL_CODES,
  DiscountType,
  DiscountTypeValue,
  EDevice,
  EFrontType,
  EPaymentState,
  EProgramType,
  ERuleType,
  ERuleTypeValue,
  EStateOrder,
  IConfigTab,
  IControl,
  ICustomer,
  IMenuItem,
  IMenuResponse,
  IOrder,
  IProduct,
  IProductResponse,
  IProductTaxon,
  IProgram,
  IPromotionCouponActionResponse,
  IPromotionCouponAssigningActionResponse,
  IPromotionCouponDiscountActionResponse,
  IPromotionCouponRuleResponse,
  IPromotionCouponsResponse,
  ISegmentConfiguration,
  TNoveltyOption,
  TTags,
} from "@middleware/types";
import { AxiosError, AxiosResponse } from "axios";
import { Content } from "@prismicio/client";
import { RichTextField } from "@prismicio/types";
import { generateDate, getNextMondayOfDate, limitText } from "./utils";
import { getIsProspect } from "./customer";
import { TagsSliderSliceDefaultItem } from "../../../../types.generated";

export const getProductDescription = (product: IProduct, short = true) => {
  return short ? limitText(product.shortDescription, 130) : product.description;
};

export const serializeProduct = (bundles: IProductResponse[]): IProduct[] => {
  const mapping = {
    [BUNDLE_CHECKOUT_ENABLED]: "checkoutEnabled",
    [BUNDLE_CODE_DEFAULT]: "isDefault",
    [BUNDLE_CODE_MEALS]: "mealsNumber",
    [BUNDLE_CODE_MEALS_MAX]: "maxMealsNumber",
    [BUNDLE_CODE_DEFAULT_COUPON_CODE]: "defaultCouponCode",
    [BUNDLE_CODE_CUSTOM]: "isCustomBundle",
    [MEAL_CODE_NUTRISCORE]: "nutriscore",
    [MEAL_CODE_FOOD_PREFERENCE]: "foodPreference",
    [MEAL_CODE_INGREDIENT_DESCRIPTION]: "ingredients",
    [MEAL_CODE_NUTRITIONAL_VALUE]: "nutritional",
    [MEAL_CODE_PREPARATION_ADVICE]: "preparation",
    [MEAL_CODE_STORAGE_INSTRUCTIONS]: "storageInstructions",
    [MEAL_CODE_NEW]: "novelty",
  };
  const newBundlesFormat = bundles.map((bundle) => {
    const attributes = bundle.attributes?.reduce((accumulator, attribute) => {
      if (attribute.localeCode !== null && attribute.localeCode !== getLocale())
        return accumulator;

      if (attribute.attributeCode === BUNDLE_ATTRIBUTE_TYPE) {
        return {
          ...accumulator,
          isCustomBundle: Array.isArray(attribute.value)
            ? CUSTOM_BUNDLE_VALUE === attribute.value[0]
            : false,
        };
      }

      if (attribute.attributeCode in mapping) {
        return {
          ...accumulator,
          [mapping[attribute.attributeCode as keyof typeof mapping]]:
            attribute.value,
        };
      }

      return accumulator;
    }, {});
    const showInBundles = bundle.bundles
      ? bundle.bundles.map((code) => code)
      : [];

    const packaging = bundle.packaging ?? 1;
    // TODO revert
    const isNew =
      (
        attributes as {
          novelty?: TNoveltyOption;
        }
      ).novelty?.values.some(
        (value) => value.new && value.channel === CHANNEL_CODES.KITCHEN_DAILY
      ) ?? false;

    return {
      id: bundle.id,
      code: bundle.code,
      codeVariant: bundle.variants[0].code,
      name: bundle.name,
      shortDescription: bundle.shortDescription,
      description: bundle.description,
      ingredients: "",
      nutritional: "",
      preparation: "",
      price: bundle.variants[0].price / packaging,
      priority: bundle.priority ?? 0,
      foodPreference: [],
      isDefault: false,
      checkoutEnabled: false,
      mealsNumber: 0,
      maxMealsNumber: 0,
      desktopPosition: bundle.desktopPosition ?? 1,
      mobilePosition: bundle.mobilePosition ?? 1,
      defaultCouponCode: "",
      nutriscore: [],
      storageInstructions: [],
      image: bundle.images.length > 0 ? bundle.images[0].path : "",
      weight: bundle.variants[0].weight,
      volume: bundle.variants[0].volume,
      packaging: packaging,
      isCustomBundle: false,
      productTaxons: bundle.productTaxons,
      isBest: attributes && MEAL_CODE_NEW in attributes && isNew ? false : true,
      isNew,
      tags: serializeTagsFromProducts(bundle.productTaxons),
      showInBundles,
      ...attributes,
    };
  });

  return newBundlesFormat;
};

export const serializeTagsFromProducts = (
  productTaxons: IProductTaxon[]
): TTags[] => {
  return productTaxons
    .filter(
      (productTaxon) => productTaxon.taxon.parent?.code === TAXON_TAGS_CODE
    )
    .map((productTaxon) => ({ code: productTaxon.taxon.code ?? "" }));
};

export const isDailyBundle = (bundle: IProduct, ecoProgram = false) => {
  return (
    bundle.productTaxons?.some(
      (item) =>
        item.taxon.code === BUNDLE_DAILY_TAXON_CODE ||
        (item.taxon.code === BUNDLE_DAILY_ECO_TAXON_CODE && ecoProgram) ||
        item.taxon.code === CODE_EXTRA_MAIN_TAXON
    ) ?? false
  );
};

export const isDailyEcoBundle = (bundle: IProduct) => {
  return (
    bundle.productTaxons?.some(
      (item) => item.taxon.code === BUNDLE_DAILY_ECO_TAXON_CODE
    ) ?? false
  );
};

export const refactoringBundles = (bundles: IProduct[]) => {
  return bundles.map((bundle) => {
    return {
      ...bundle,
      mealsNumber: isDailyEcoBundle(bundle)
        ? bundle.mealsNumber / 2
        : bundle.mealsNumber,
    };
  });
};

export const sortBundles = (bundles: IProduct[], ordre = "ASC"): IProduct[] => {
  return ordre === "ASC"
    ? bundles.sort((a, b) => (a.mealsNumber > b.mealsNumber ? 1 : -1))
    : bundles.sort((a, b) => (a.mealsNumber < b.mealsNumber ? 1 : -1));
};

export const sortProductByUser = (product: IProduct, isProspect: boolean) => {
  if (isProspect) {
    return product.isBest ? 1 : product.isNew ? 2 : 3;
  }

  return product.isNew ? 1 : product.isBest ? 2 : 3;
};

export const sortProductsByDevice = (products: IProduct[], device: EDevice) => {
  return device === EDevice.MOBILE
    ? products.sort((a, b) => (a.mobilePosition > b.mobilePosition ? 1 : -1))
    : products.sort((a, b) => (a.desktopPosition > b.desktopPosition ? 1 : -1));
};
export const sortProductsByPriority = (products: IProduct[]) => {
  return products.sort((a, b) =>
    a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : a.id - b.id
  );
};

export const sortProductByAttr = (
  products: IProduct[],
  customer: ICustomer | undefined
): IProduct[] => {
  const isProspect = getIsProspect(customer);

  const newProducts = sortProductsByPriority(
    products.filter((prod) => prod.isNew)
  );
  const bestProducts = sortProductsByPriority(
    products.filter((prod) => prod.isBest)
  );

  if (isProspect) {
    return [...bestProducts, ...newProducts];
  }

  return [...newProducts, ...bestProducts];
};

export const sortMeals = (meals: IProduct[]): IProduct[] => {
  return meals
    .sort((a, b) => (a.priority > b.priority ? 1 : -1))
    .map((meal, index) => ({ ...meal, priority: index }));
};

export const getProductsFromResponse = <T>(
  productResponse: AxiosResponse<T> | AxiosResponse<AxiosError>,
  order = "ASC",
  ecoProgram = false
): IProduct[] => {
  return sortBundles(
    serializeProduct(productResponse.data as IProductResponse[]),
    order
  ).filter((bundle) => isDailyBundle(bundle, ecoProgram));
};

export const getProductsOfMenu = (
  productResponse: AxiosResponse | AxiosResponse<AxiosError>
): IMenuItem => {
  const menuItemsResponse = productResponse.data as IMenuResponse;
  const productsResponse: IProductResponse[] = menuItemsResponse.items.map(
    (item) => {
      return {
        ...item.product,
        priority: item.priority,
      };
    }
  );

  return {
    meals: sortMeals(serializeProduct(productsResponse)),
    weeklyMenuCode: menuItemsResponse.weeklyMenuCode,
  };
};
export const isPromotionFirstOrder = (
  promotionRules: IPromotionCouponRuleResponse[]
) => {
  const promotionRule = promotionRules.find(
    (promotion) => promotion.type === ERuleType.RULE_FIRST_ORDER
  );
  if (promotionRule !== undefined)
    return promotionRule.configuration[ERuleTypeValue.NTH] === 1;

  return false;
};
export const getPromotionSubscriptionTypeCode = (
  promotionActions: IPromotionCouponActionResponse[]
): string | null => {
  const promotionGroupAction = promotionActions
    .filter((action): action is IPromotionCouponAssigningActionResponse =>
      Object.values(AssigningType).some((value) => value === action.type)
    )
    .find((action) => action.type === AssigningType.SUBSCRIPTION_ASSIGNER);

  if (promotionGroupAction === undefined) return null;

  return promotionGroupAction.configuration["subscription_type_code"];
};

export const getPromotionGroupCode = (
  promotionActions: IPromotionCouponActionResponse[]
): string | null => {
  const promotionGroupAction = promotionActions
    .filter((action): action is IPromotionCouponAssigningActionResponse =>
      Object.values(AssigningType).some((value) => value === action.type)
    )
    .find((action) => action.type === AssigningType.GROUP_ASSIGNER);

  if (promotionGroupAction === undefined) return null;

  return promotionGroupAction.configuration["group"];
};

export const getPromotionDiscountAmount = (
  discountType: DiscountType,
  promotionAction: IPromotionCouponDiscountActionResponse
): number => {
  const promotionConfiguration = promotionAction.configuration;

  if (CHANNEL_CODES.KITCHEN_DAILY in promotionConfiguration) {
    const channelConfiguration =
      promotionConfiguration[CHANNEL_CODES.KITCHEN_DAILY];

    switch (discountType) {
      case DiscountType.ORDER_PERCENTAGE:
      case DiscountType.UNIT_PERCENTAGE:
        return channelConfiguration[DiscountTypeValue.PERCENTAGE];
      case DiscountType.VARIANT_FIXED:
      case DiscountType.ORDER_FIXED:
      case DiscountType.UNIT_FIXED:
        return channelConfiguration[DiscountTypeValue.AMOUNT];
      default:
        return 0;
    }
  }

  switch (discountType) {
    case DiscountType.ORDER_PERCENTAGE:
      return promotionConfiguration[DiscountTypeValue.PERCENTAGE];
    default:
      return 0;
  }
};
export const getPromotionSegments = (
  promotionRules: IPromotionCouponRuleResponse[]
) => {
  const promotionRule = promotionRules.find(
    (promotion) => promotion.type === ERuleType.RULE_SEGMENT
  );
  if (promotionRule !== undefined)
    return promotionRule.configuration[
      ERuleTypeValue.SEGMENT
    ] as ISegmentConfiguration;

  return undefined;
};
export const getPromotionVariants = (
  promotionAction: IPromotionCouponDiscountActionResponse
): string[] => {
  const promotionConfiguration = promotionAction.configuration;

  if (CHANNEL_CODES.KITCHEN_DAILY in promotionConfiguration) {
    const channelConfiguration =
      promotionConfiguration[CHANNEL_CODES.KITCHEN_DAILY];

    return channelConfiguration.variants_filter?.variants ?? [];
  }

  return [];
};
export const getPromotionCouponFromResponse = (
  promotionCouponsResponse: IPromotionCouponsResponse,
  promotionCoupon: string
): BundlePromotionDetails[] | null => {
  const promotionDiscountActions = promotionCouponsResponse.actions
    .filter((action): action is IPromotionCouponDiscountActionResponse =>
      Object.values(DiscountType).some((value) => value === action.type)
    )
    .filter((action) => Object.values(DiscountType).includes(action.type));

  if (promotionDiscountActions.length === 0) return null;

  const groupCode = getPromotionGroupCode(promotionCouponsResponse.actions);
  const subscriptionTypeCode = getPromotionSubscriptionTypeCode(
    promotionCouponsResponse.actions
  );
  const firstOrder = isPromotionFirstOrder(promotionCouponsResponse.rules);
  const segments = getPromotionSegments(promotionCouponsResponse.rules);
  const promotionCoupons: BundlePromotionDetails[] = [];

  promotionDiscountActions.map((promotionDiscountAction) => {
    const discountType = promotionDiscountAction.type;
    const discountAmount = getPromotionDiscountAmount(
      discountType,
      promotionDiscountAction
    );
    promotionCoupons.push({
      code: promotionCouponsResponse.code,
      promotionCoupon,
      discountType,
      discountAmount,
      firstOrder,
      groupCode,
      subscriptionTypeCode,
      segments,
      variants: getPromotionVariants(promotionDiscountAction),
    });

    return promotionDiscountAction;
  });

  return promotionCoupons.length > 0 ? promotionCoupons : null;
};

export const getBundleByCode = (
  bundles: IProduct[],
  bundleCode: string
): IProduct | undefined => {
  return bundles.find((singleBundle) => singleBundle.code === bundleCode);
};

export const getDefaultFormula = (
  forcingFront: string | null,
  defaultFormula: number,
  bundles: IProduct[]
): number => {
  if (forcingFront === null) return defaultFormula;

  // form param url <> form8, form10...
  const mealsNumber = forcingFront.replace("form", "");
  const defaulBundle = bundles.find(
    (bundle) => bundle.mealsNumber === parseInt(mealsNumber)
  );
  if (defaulBundle) return defaulBundle.mealsNumber;

  return defaultFormula;
};

const createTab = (taxonItem: IProductTaxon): IConfigTab => ({
  code: taxonItem.taxon.code ?? "",
  label: taxonItem.taxon.name ?? "",
  position: taxonItem.taxon.position ?? 0,
});

export const serializeConfigs = (products: IProduct[]): IConfigTab[] => {
  const initConfigs: IConfigTab[] = [
    {
      code: undefined,
      label: "programmes.all",
      size: 25,
      position: -1,
    },
  ];
  const generatedTabs: IConfigTab[] = products.reduce(
    (allConfigs: IConfigTab[], item) => {
      const existingCodes = new Set(allConfigs.map((config) => config.code));

      const newConfigs = (item.productTaxons ?? []).reduce(
        (configs: IConfigTab[], taxonItem) => {
          const { taxon } = taxonItem;
          if (
            taxon.code !== undefined &&
            taxon.parent?.code === CODE_EXTRA_MAIN_TAXON &&
            !existingCodes.has(taxon.code)
          ) {
            const newTab = createTab(taxonItem);
            configs.push(newTab);
            existingCodes.add(taxon.code);
          }

          return configs;
        },
        []
      );

      return [...allConfigs, ...newConfigs];
    },
    initConfigs
  );

  return generatedTabs;
};

export const getBundleByNumberMeals = (
  bundles: IProduct[],
  mealsNumber: number
) => {
  if (bundles.length === 0) return undefined;

  return bundles.find((bundle) => bundle.mealsNumber === mealsNumber);
};

export const getNumberMealMaxOfBundles = (bundles: IProduct[]) => {
  if (bundles.length === 0) return 0;

  return Math.max(...bundles.map((item) => item.mealsNumber));
};

export const getNumberMealMinOfBundles = (bundles: IProduct[]) => {
  if (bundles.length === 0) return 0;

  return Math.min(...bundles.map((item) => item.mealsNumber));
};

export const setDefaultProductQuantities = (
  selectedProducts: IProduct[],
  formula: number
) => {
  const lengthProducts = selectedProducts.length;
  const defaultQty = Math.floor(formula / lengthProducts);
  const remainer = formula - lengthProducts * defaultQty;

  const newControl = {
    products: selectedProducts.map((product, index) => {
      return {
        id: product.id,
        quantity: defaultQty + (index < remainer ? 1 : 0),
        code: product.codeVariant,
        price: product.price,
        packaging: product.packaging,
      };
    }),
  };

  return newControl as IControl;
};

export const getPlatsOptions = (
  nombrePersonnes: number,
  bundles: IProduct[]
) => {
  return bundles
    .map((bundle) => bundle.mealsNumber)
    .filter((plats) => plats % nombrePersonnes === 0)
    .map((plats) => plats / nombrePersonnes);
};

export const getCustomerBundle = (bundles: IProduct[], customer: ICustomer) => {
  const currentBundleCode = customer.currentSubscription?.bundle.code;

  return bundles.find((bundle) => bundle.codeVariant === currentBundleCode);
};

export const getTagCode = (name: string): string => {
  const tagsCodes: Record<string, string> = {
    "Contrôle calories": FORMULES_TAG_CONTROL_CODE,
    "protéines +": FORMULES_TAG_PROTEINE_CODE,
    Généreux: FORMULES_TAG_GENEREUX_CODE,
    Végétarien: FORMULES_TAG_VEGE_CODE,
  };

  return name in tagsCodes ? tagsCodes[name] : "";
};

export const serializeTags = (items: TagsSliderSliceDefaultItem[]): TTags[] => {
  return items.map((item) => ({
    name: item.code?.toString() ?? "",
    caption: item.caption?.toString() ?? "",
    code: getTagCode(item.code?.toString() ?? ""),
    icon: item.icon.url ?? "",
    image: item.image.url ?? "",
  }));
};

export const excludeTags = (forcingFront: EFrontType | null, tags: TTags[]) => {
  if (forcingFront === null) return tags;

  if (forcingFront === EFrontType.STYLE5) {
    const tagsToExclude = [FORMULES_TAG_GENEREUX_CODE, FORMULES_TAG_VEGE_CODE];

    return tags.filter((tag) => !tagsToExclude.includes(tag.code));
  }
  if (forcingFront === EFrontType.STYLE6) {
    const tagsToExclude = [FORMULES_TAG_VEGE_CODE];

    return tags.filter((tag) => !tagsToExclude.includes(tag.code));
  }
  if (forcingFront === EFrontType.STYLE7) {
    const tagsToExclude = [FORMULES_TAG_GENEREUX_CODE];

    return tags.filter((tag) => !tagsToExclude.includes(tag.code));
  }

  return tags;
};
export const getUsedTagsFromProducts = (products: IProduct[]) => {
  return Array.from(
    new Set(
      products.flatMap((product) => product.tags?.map((tag) => tag.code) ?? [])
    )
  );
};
export const sortProductsByTags = (
  tag: string | undefined,
  products: IProduct[],
  customer?: ICustomer
) => {
  const sortOrders: Record<string, string[]> = {
    [FORMULES_TAG_PROTEINE_CODE]: [
      FORMULES_TAG_PROTEINE_CODE,
      FORMULES_TAG_CONTROL_CODE,
      FORMULES_TAG_VEGE_CODE,
      FORMULES_TAG_GENEREUX_CODE,
    ],
    [FORMULES_TAG_CONTROL_CODE]: [
      FORMULES_TAG_CONTROL_CODE,
      FORMULES_TAG_PROTEINE_CODE,
      FORMULES_TAG_VEGE_CODE,
      FORMULES_TAG_GENEREUX_CODE,
    ],
    [FORMULES_TAG_VEGE_CODE]: [
      FORMULES_TAG_VEGE_CODE,
      FORMULES_TAG_PROTEINE_CODE,
      FORMULES_TAG_CONTROL_CODE,
      FORMULES_TAG_GENEREUX_CODE,
    ],
    [FORMULES_TAG_GENEREUX_CODE]: [
      FORMULES_TAG_GENEREUX_CODE,
      FORMULES_TAG_PROTEINE_CODE,
      FORMULES_TAG_CONTROL_CODE,
      FORMULES_TAG_VEGE_CODE,
    ],
  };

  if (tag === undefined) return sortProductByAttr(products, customer);

  const sortOrder = sortOrders[tag];

  return [...products].sort((a, b) => {
    const aTag =
      a.tags?.find((tagItem) => sortOrder.includes(tagItem.code)) ?? null;
    const bTag =
      b.tags?.find((tagItem) => sortOrder.includes(tagItem.code)) ?? null;

    if (aTag === null && bTag !== null) return 1;
    if (bTag === null && aTag !== null) return -1;
    if (aTag === null && bTag === null) return 0;

    return (
      sortOrder.indexOf(aTag?.code as string) -
      sortOrder.indexOf(bTag?.code as string)
    );
  });
};
export const getIssueMessage = (
  data: Content.PaymentReminderBannerDocumentData,
  paymentState: string
): RichTextField => {
  switch (paymentState) {
    case EPaymentState.FIRST_ISSUE:
      return data.messageAttempt1;
    case EPaymentState.SECOND_ISSUE:
      return data.messageAttempt2;
    case EPaymentState.THIRD_ISSUE:
      return data.messageAttempt3;
    case EPaymentState.FOURTH_ISSUE:
      return data.messageAttempt4;
    case EPaymentState.FIFTH_ISSUE:
      return data.messageAttempt5;
    case EPaymentState.SIXTH_ISSUE:
      return data.messageAttempt6;
    case EPaymentState.CLOSED_MANUALLY:
      return data.messageAttempt7;
    case EPaymentState.CLOSED_AUTOMATICALLY:
      return data.messageAttempt8;
    default:
      return data.messageAttempt1;
  }
};

export const getOrderFailedPaymentLevel = (order: IOrder) => {
  const failedPaymentLevels = FAILED_PAYMENT_STATES;
  if (
    failedPaymentLevels.includes(order.paymentState) &&
    generateDate(order.chosenDeliveryDate) > generateDate()
  )
    return order.paymentState;

  return null;
};

export const getIsIssuerOrder = (order: IOrder | undefined) => {
  if (order === undefined) return false;

  return (
    getOrderFailedPaymentLevel(order) !== null &&
    order.state !== EStateOrder.ORDER_STATE_SKIPPED
  );
};

export const getIsDisabledOrder = (order: IOrder, customer?: ICustomer) => {
  if (customer === undefined) return false;
  const nextUpcomingOrder =
    customer.currentSubscription?.nextUpcomingOrderToPay;
  const isPastOrder =
    generateDate(order.chosenDeliveryDate) <
    generateDate(nextUpcomingOrder?.chosenDeliveryDate);
  const isUnpaidFirstOrder =
    order.id !== nextUpcomingOrder?.id &&
    getIsIssuerOrder(nextUpcomingOrder as IOrder);

  return !isPastOrder && isUnpaidFirstOrder;
};

export const getIsDangerOrder = (order: IOrder, customer?: ICustomer) => {
  if (customer === undefined) return false;

  const nextMonday = getNextMondayOfDate(order.chosenDeliveryDate);
  const isPastOrder = generateDate(nextMonday) < generateDate();
  const isUnpaidOrder = getIsIssuerOrder(order);
  const isNextUpcomingOrder =
    customer.currentSubscription?.nextUpcomingOrderToPay?.id === order.id;

  return !isPastOrder && isUnpaidOrder && isNextUpcomingOrder;
};

export const getIfCanPayReguleOrder = (order?: IOrder) => {
  if (order === undefined) return false;

  return [
    EPaymentState.FIRST_ISSUE,
    EPaymentState.SECOND_ISSUE,
    EPaymentState.THIRD_ISSUE,
    EPaymentState.FOURTH_ISSUE,
    EPaymentState.FIFTH_ISSUE,
    EPaymentState.SIXTH_ISSUE,
    EPaymentState.AWAITING,
    EPaymentState.CONTENTIOUS,
    EPaymentState.FILACTION,
    EPaymentState.IRRECOVERABLE,
  ].includes(order.paymentState);
};

const hasTaxon = (taxons: IProductTaxon[] | undefined, taxonCode: string) => {
  if (taxons === undefined) return false;

  return taxons.some((item) => item.taxon.code === taxonCode);
};

export const getBundleProgramType = (taxons: IProductTaxon[] | undefined) => {
  if (taxons === undefined) return EProgramType.NONE;

  if (hasTaxon(taxons, BUNDLE_DAILY_TAXON_CODE)) return EProgramType.TRAITEUR;
  if (hasTaxon(taxons, BUNDLE_DAILY_ECO_TAXON_CODE)) return EProgramType.ECO;

  return EProgramType.NONE;
};

export const getAllPrograms = (bundles: IProduct[]) => {
  const programs: IProgram[] = [];
  bundles.map((bundle) => {
    programs.push({
      code: bundle.code,
      productTaxon: getBundleProgramType(bundle.productTaxons),
      price: bundle.price,
      mealsNumber: bundle.mealsNumber,
      defaultCouponCode: bundle.defaultCouponCode,
      codeVariant: bundle.codeVariant,
    });

    return bundle;
  });

  return programs.filter((program) => {
    return (
      program.productTaxon === EProgramType.TRAITEUR ||
      program.productTaxon === EProgramType.ECO
    );
  });
};

export const getProgramDetails = (
  programType: EProgramType,
  programsDetails?: Content.ProgramsDocument
) => {
  if (programsDetails === undefined) return undefined;

  const serializeProgramDetails = {
    [EProgramType.TRAITEUR]: "Catering",
    [EProgramType.ECO]: "Eco",
    [EProgramType.NONE]: "",
  };

  return programsDetails.data.slices.find(
    (program) =>
      program.primary.programeType === serializeProgramDetails[programType]
  );
};
