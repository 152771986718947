import { IOrder, IOrderItem } from "@middleware/types";
import {
  BUNDLE_DAILY_ECO_TAXON_CODE,
  CODE_BUNDLE_MAIN_TAXON,
  CODE_MEAL_MAIN_TAXON,
  URL_PAGE_FORMULES,
  URL_PROGRAMME_DAILY,
} from "@middleware/constants";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  getItemOrderMealsNumber,
  hasOrderItemTaxon,
} from "@middleware/helpers";

export const useBundle = (cart: IOrder | undefined) => {
  const [isValidBundle, setValidBundle] = useState(false);
  const [mealsNumber, setMealsNumber] = useState(0);
  const [realMealsNumber, setRealMealsNumber] = useState(0);
  const [bundleCartItem, setBundleCartItem] = useState<IOrderItem>();
  const [isEco, setIsEco] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (cart) {
      const currentBundle = {
        exist: false,
        mealsNumber: 0,
        meals: 0,
        code: "",
      };

      const validate = async () => {
        // code temporaire
        // faut ajouter des flag "isBundle", "isExtras" sur les items de la cart
        cart.items.map((item) => {
          if (item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON) {
            currentBundle.exist = true;
            const numberFormulaMeals = getItemOrderMealsNumber(item);
            currentBundle.mealsNumber = numberFormulaMeals;
            currentBundle.code = item.productCode;
            setMealsNumber(numberFormulaMeals);
            setBundleCartItem(item);
          } else if (item.productTypeTaxon === CODE_MEAL_MAIN_TAXON) {
            currentBundle.meals += item.quantity;
          }

          return true;
        });

        if (!currentBundle.exist) {
          await router.push(URL_PAGE_FORMULES);
        } else if (
          currentBundle.mealsNumber === 0 ||
          currentBundle.mealsNumber !== currentBundle.meals
        ) {
          const codeBundle = currentBundle.code;
          await router.push({
            pathname: URL_PROGRAMME_DAILY,
            query: { code: codeBundle },
          });
        } else {
          setValidBundle(true);
        }
      };

      void validate();
    }
  }, [cart, router]);

  useEffect(() => {
    const isEcoOrder = hasOrderItemTaxon(
      bundleCartItem,
      BUNDLE_DAILY_ECO_TAXON_CODE
    );
    setIsEco(isEcoOrder);
  }, [bundleCartItem]);

  useEffect(() => {
    const number = isEco ? mealsNumber / 2 : mealsNumber;
    setRealMealsNumber(number);
  }, [isEco, mealsNumber]);

  return { isValidBundle, mealsNumber, bundleCartItem, isEco, realMealsNumber };
};
