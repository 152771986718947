import { useEffect, useState } from "react";
import { ICustomer, IOrder } from "@middleware/types";
import { useAuth } from "./useAuth";

export const useCustomer = () => {
  const { user, customer } = useAuth();
  const [currentCustomer, setCustomer] = useState<ICustomer>();
  const [lastOrder, setLastOrder] = useState<IOrder>();

  useEffect(() => {
    const getCustomer = () => {
      if (user && customer) {
        const newCustomer = { ...customer, id: user.id };
        setCustomer(newCustomer);
        const allUpComingOrder = Object.values(customer.allUpcomingOrders);
        if (allUpComingOrder.length > 0) {
          const lastCustomerOrder = allUpComingOrder.pop();
          setLastOrder(lastCustomerOrder);
        }
      }
    };

    void getCustomer();
  }, [user, customer]);

  return { customer: currentCustomer, setCustomer, lastOrder };
};
