import { isEnvironmentStagingMode } from "@middleware/helpers";
import { useEffect, useState } from "react";

export const useStagingMode = () => {
  const [isStagingMode, setIsStagingMode] = useState<boolean>(false);

  useEffect(() => {
    const getEnvironment = () => {
      const stagingMode = isEnvironmentStagingMode();
      setIsStagingMode(stagingMode);
    };
    void getEnvironment();
  }, []);

  return { isStagingMode };
};
