import React, { useEffect, useState } from "react";
import { Text } from "@components/ui";
import { useCustomer, useUnpaidOrder } from "@middleware/hooks";
import { JSXMapSerializer, PrismicRichText } from "@prismicio/react";
import { useRouter } from "next/router";
import {
  URL_PAGE_CUSTOMER_ORDER,
  URL_PAGE_CUSTOMER_PAYMENT,
  URL_PAGE_SHIPMENT_DETAILS,
} from "@middleware/constants";
import { getIfCanPayReguleOrder } from "@middleware/helpers";
import style from "./PaymentReminderBanner.module.scss";

export default function PaymentReminderBanner() {
  const { customer } = useCustomer();
  const { issueOrder, message, reguleOrder, closedSubscriptionNoPaid } =
    useUnpaidOrder(customer);
  const [showBanner, setShowBanner] = useState(false);
  const router = useRouter();
  const isPaymentPage = router.pathname === URL_PAGE_CUSTOMER_PAYMENT;
  const hasReguleOrder = reguleOrder !== undefined;
  const canPayReguleOrder = getIfCanPayReguleOrder(reguleOrder);
  const redirectionUrl = hasReguleOrder
    ? `${URL_PAGE_CUSTOMER_ORDER}`
    : `${URL_PAGE_SHIPMENT_DETAILS}${issueOrder?.tokenValue ?? ""}`;
  const customSerializers: JSXMapSerializer = {
    hyperlink: ({ node, children, key }) => {
      const { data } = node;

      return (
        <a
          key={key}
          href={data.url}
          onClick={async (e) => {
            e.preventDefault();
            await router.push(redirectionUrl);
          }}
        >
          {children}
        </a>
      );
    },
  };
  useEffect(() => {
    setShowBanner(
      !isPaymentPage &&
        message !== undefined &&
        (issueOrder !== undefined || canPayReguleOrder) &&
        customer !== undefined
    );
  }, [
    isPaymentPage,
    message,
    issueOrder,
    customer,
    closedSubscriptionNoPaid,
    canPayReguleOrder,
  ]);

  return showBanner ? (
    <div className={style.banner}>
      <div className="lk-wrap">
        <div className={style.flex}>
          <div className={style.text}>
            <Text variant="body">
              <PrismicRichText field={message} components={customSerializers} />
            </Text>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
