import Api from "@middleware/api";
import {
  getNumericDate,
  getVariantCodeBundleOfOrder,
} from "@middleware/helpers";
import { IOrder, IProduct } from "@middleware/types";
import { useEffect, useState } from "react";

export const useWeeklyMeals = (order?: IOrder) => {
  const [meals, setMeals] = useState<IProduct[]>();
  const [weeklyMenuCode, setWeeklyMenuCode] = useState<string>("");
  const [undefinedMenu, setUndefinedMenu] = useState<boolean>();

  useEffect(() => {
    const getWeeklyMeals = async () => {
      if (order) {
        const weekDate = getNumericDate(order.chosenDeliveryDate);
        const bundleCode = getVariantCodeBundleOfOrder(order);
        const menuItem = await Api.catalog.getWeeklyMealsByBundle(
          weekDate,
          bundleCode
        );
        if (menuItem) {
          setMeals(menuItem.meals);
          setWeeklyMenuCode(menuItem.weeklyMenuCode);

          return;
        }

        setUndefinedMenu(true);
      }
    };
    void getWeeklyMeals();
  }, [order]);

  return { meals, setMeals, weeklyMenuCode, undefinedMenu };
};
